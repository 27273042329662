import { Subject } from 'rxjs'
import { ICampaignModel, IUserModel } from 'src/interfaces'

const _onUpdateUser$ = new Subject<Omit<Partial<IUserModel>, 'id'> & { id: number }>()
const _onUpdateCampaign$ = new Subject<{
  id: number
  slug: string
} & Omit<Partial<ICampaignModel>, 'id' | 'slug'>>()

export class EventService {
  static readonly onUpdateUser$ = _onUpdateUser$.asObservable()
  static updateUser(user: Omit<Partial<IUserModel>, 'id'> & { id: number }) {
    _onUpdateUser$.next(user)
  }

  static readonly onUpdateCampaign$ = _onUpdateCampaign$.asObservable()
  static updateCampaign(campaign: Omit<Partial<ICampaignModel>, 'id' | 'slug'> & {
    id: number
    slug: string
  }) {
    _onUpdateCampaign$.next(campaign)
  }
}
