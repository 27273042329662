import { ICampaignModel } from './campaign.model'
import { IModel } from './model'

export enum EScrapedJobProvider {
  LINKEDIN = 'linkedin',
  INDEED = 'indeed',
  GLASSDOOR = 'glassdoor',
  ZIPRECRUITER = 'ziprecruiter'
}

export const ScrapedJobProviderOptions = Object.freeze([
  { label: 'Source', value: '', disabled: true },
  { label: 'LinkedIn', value: EScrapedJobProvider.LINKEDIN },
  { label: 'Indeed', value: EScrapedJobProvider.INDEED },
  { label: 'Glassdoor', value: EScrapedJobProvider.GLASSDOOR },
  { label: 'ZipRecruiter', value: EScrapedJobProvider.ZIPRECRUITER }
])

export interface IScrapedJobModel extends IModel {
  provider: EScrapedJobProvider | 'linkedin' | 'indeed' | 'ziprecruiter'
  jobId: string
  jobUrl?: string
  jobTitle: string
  jobType?: string
  jobAddress?: string
  jobWorkingType?: string
  applicants: string
  description: string
  status: string
  companyLogo?: string
  companyUrl?: string
  companyName: string
  employeesFrom?: number
  employeesPlus?: boolean
  employeesTo?: number
  recruiterUrl?: string
  recruiterName?: string
  recruiterBio?: string
  contactInfo?: string
  connected?: boolean
  noMatched?: boolean
  campaignId?: number
  campaign?: ICampaignModel
}
