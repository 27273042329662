import { IRouterOption } from 'src/router'
import { Feedbacks } from './components/feedbacks'
import { MissingCity } from './components/missing-city'
import { MissingRole } from './components/missing-role'
import { EPaths } from './routes.path'

export enum ERoutes {
  MISSING_ROLE = 'Feedback.MISSING_ROLE',
  MISSING_CITY = 'Feedback.MISSING_CITY',
  FEEDBACK = 'Feedback.List'
}

export const Routes: IRouterOption[] = [
  {
    path: EPaths.MISSING_ROLE,
    component: MissingRole,
    name: ERoutes.MISSING_ROLE,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.MISSING_CITY,
    component: MissingCity,
    name: ERoutes.MISSING_CITY,
    exact: true,
    meta: {}
  },
  {
    path: EPaths.FEEDBACK,
    component: Feedbacks,
    name: ERoutes.FEEDBACK,
    exact: true,
    meta: {}
  }
]

export const FeedbackModule = {
  ERoutes,
  Routes
}
