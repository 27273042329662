import { Form, Input } from 'antd'
import { TextAreaRef } from 'antd/lib/input/TextArea'
import { useRef } from 'react'
import { ENotificationPlaceholder } from '../../constants'

interface EmailBodyInputProps {
  formData: { body: string }
  handleChangeInput: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  errors: { hasError: (field: any) => boolean; getError: (field: any) => string | undefined}
  loading: boolean
}

const placeholders = [
  {
    label: 'First Name',
    value: ENotificationPlaceholder.FIRST_NAME
  },
  {
    label: 'Full Name',
    value: ENotificationPlaceholder.FULL_NAME
  },
  {
    label: 'Email',
    value: ENotificationPlaceholder.EMAIL
  }
  // {
  //   label: 'VVC Views',
  //   value: ENotificationPlaceholder.VVC_VIEWS
  // },
  // {
  //   label: 'VVC Likes',
  //   value: ENotificationPlaceholder.VVC_LIKES
  // },
  // {
  //   label: 'HM Company Name Liked',
  //   value: ENotificationPlaceholder.HM_COMPANY_NAME_LIKED
  // },
  // {
  //   label: 'HM Company Name Viewed VVC',
  //   value: ENotificationPlaceholder.HM_COMPANY_NAME_VIEWED_VVC
  // }
]

const EmailBodyInput: React.FC<EmailBodyInputProps> = ({ formData, handleChangeInput, errors, loading }) => {
  const textAreaRef = useRef<TextAreaRef | null>(null)

  const insertPlaceholder = (placeholder: string) => {
    if (!textAreaRef.current) return

    const textArea = textAreaRef.current.resizableTextArea?.textArea
    if (!textArea) return
    const { selectionStart, selectionEnd } = textArea

    const newBody =
      formData.body.substring(0, selectionStart) +
      placeholder +
      formData.body.substring(selectionEnd)

    handleChangeInput({ target: { name: 'body', value: newBody } } as React.ChangeEvent<HTMLTextAreaElement>)

    textArea.focus()
    setTimeout(() => {
      textArea.setSelectionRange(selectionStart + placeholder.length, selectionStart + placeholder.length)
    }, 0)
  }

  return (
    <Form.Item
      label="Body"
      labelAlign="left"
      labelCol={{ flex: '0 0 100px' }}
      className="m-0"
      style={{ position: 'relative' }}
    >
      <div
        className="dropdown-menu"
        style={{
          display: 'flex',
          backgroundColor: '#f5f5f5',
          border: '1px solid #d9d9d9',
          borderRadius: '4px',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
          zIndex: 10
        }}
      >
        {placeholders.map((placeholder, index) => (
          <div
            key={placeholder.value}
            className="dropdown-item"
            style={{
              padding: '8px',
              cursor: 'pointer',
              borderRight: '1px solid #d9d9d9',
              fontSize: '12px'
              // grey
            }}
            onMouseDown={() => insertPlaceholder(placeholder.value)}
          >
            {placeholder.label}
          </div>
        ))}
      </div>
      <Input.TextArea
        ref={textAreaRef}
        rows={4}
        readOnly={loading}
        name="body"
        placeholder="Body"
        value={formData.body}
        status={errors.hasError('body') ? 'error' : undefined}
        onChange={handleChangeInput}
      />
      <div style={{ color: 'red' }}>{errors.getError('body')}</div>
    </Form.Item>
  )
}

export default EmailBodyInput
