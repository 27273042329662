import { Input, Pagination, Select, Space, Table } from 'antd'
import { omit } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { NotificationTemplateApi } from 'src/api'
import { ModalNotificationTemplate } from 'src/components'
import { EPlatformType, ERoleType } from 'src/constants/enum'
import { IPaginateCallback, IPaginateParams, useDidMountDebounce, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { INotificationTemplateModel, IPaginationResponse } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { useAuthorization } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const PAGE_TITLE = 'Notification Template'

export const NotificationTemplate: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { is } = useAuthorization()
  const [loading, setLoading] = useState(false)
  const _paginationService = useMemo(() => new PaginationService<INotificationTemplateModel>(NotificationTemplateApi), [])
  const [dataSource, setDataSource] = useState<IPaginationResponse<INotificationTemplateModel>>({
    total: 1,
    rows: []
  })

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(
    {
      ...omit(pagination, ['page', 'limit', 'offset']),
      platform: EPlatformType.ALL
    }
  )

  const onSend = useCallback((record: INotificationTemplateModel) => {
    setLoading(true)
    from(NotificationTemplateApi.send(record.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => NotifyUtils.success({ message: 'Notifications has been sent' }),
        error: NotifyUtils.handleAxiosError
      })
  }, [unsubscribe$])

  const onUpdate = useCallback((record: INotificationTemplateModel) => {
    setDataSource((prev) => ({
      ...prev,
      rows: prev.rows?.map(
        (item) => item.id === record.id
          ? { ...item, ...record }
          : item
      )
    }))
  }, [])

  const onDelete = useCallback((record: INotificationTemplateModel) => {
    setLoading(true)
    from(NotificationTemplateApi.destroy(record.id))
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {
          fetch(pagination)
          NotifyUtils.success({ message: 'Delete notification template successfully' })
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [fetch, pagination, unsubscribe$])

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  useEffect(() => {
    _paginationService
      .loading$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((value) => setLoading(value))
    _paginationService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((data) => setDataSource(data))
  }, [_paginationService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.NOTIFICATION_TEMPLATE,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend gap-2">
          <Input
            allowClear
            placeholder="Search by title, body..."
            style={{ flex: '0 0 30vw' }}
            readOnly={loading}
            value={filter.keyword as string}
            onChange={(e) => {
              e.persist()
              setFilter((prev) => ({
                ...prev,
                keyword: e.target.value
              }))
            }}
          />
        </div>

        <Space direction="horizontal" size="small">
          <Select value={filter.platform} onChange={(value) => setFilter({ ...filter, platform: value })} style={{ width: '120px' }}>
            <Select.Option value={EPlatformType.ALL}>All</Select.Option>
            <Select.Option value={EPlatformType.HIRING_MANAGER}>Hiring Manager</Select.Option>
            <Select.Option value={EPlatformType.TALENT}>Talent</Select.Option>
          </Select>

          <ModalNotificationTemplate
            afterClose={(saved) => saved && dataSource.page === 1 && fetch(pagination)}
          >Create
          </ModalNotificationTemplate>
        </Space>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns({
          onSend: is(ERoleType.SUPER_ADMIN)
            ? onSend
            : undefined,
          onUpdate,
          onDelete: is(ERoleType.SUPER_ADMIN)
            ? onDelete
            : undefined
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />
    </section>
  )
}
