import { Button, Slider } from 'antd'
import clsx from 'clsx'
import { FC, useCallback, useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import { EMPTY, catchError, from, takeUntil } from 'rxjs'
import { UserApi, VideoApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { IVideoModel } from 'src/interfaces'
import { EventService } from 'src/services'
import { NotifyUtils, VideoUtils } from 'src/utils'

interface IProps {
  className?: string
  player?: ReactPlayer
  video?: IVideoModel
  encoding?: {
    videoId: number
    secondAt: number
  }
}

export const VideoThumbPicker: FC<IProps> = (props) => {
  const { player, video, encoding } = props
  const videoId = video?.id
  const userId = video?.userIdAuthor
  const unsubscribe$ = useUnsubscribe()
  const [generatedThumb, setGeneratedThumb] = useState(false)
  const [generatedPfp, setGeneratedPfp] = useState(false)
  const [duration, setDuration] = useState(0)
  const [thumbnailAt, setThumbnailAt] = useState(30)

  useEffect(() => {
    if (player && !duration) {
      setDuration(player.getDuration())
    }
  }, [duration, player])

  const onPicker = useCallback((val: number) => {
    if (!player) {
      return NotifyUtils.info({ message: 'Please play video first 🙏' })
    }
    player?.seekTo(player.getDuration() * val / 100)
    setThumbnailAt(val)
  }, [player])

  const onSubmitThumb = useCallback(() => {
    if (!videoId) {
      return
    }

    setGeneratedThumb(true)
    from(VideoApi.saveThumbnail(videoId, thumbnailAt))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          setGeneratedThumb(false)
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        })
        // finalize(() => setLoading(false))
      )
      .subscribe(() => {
        NotifyUtils.success({ message: 'Save thumbnail successfully' })
      })
  }, [thumbnailAt, unsubscribe$, videoId])

  const onSubmitPfp = useCallback(() => {
    if (!videoId || !userId) {
      return
    }
    player?.seekTo(thumbnailAt)
    const element = player?.getInternalPlayer() as HTMLVideoElement
    if (!element) {
      return
    }
    const promise = (async () => {
      const file = await VideoUtils.captureSnapshot(element, thumbnailAt)
      return UserApi.uploadAvatar(userId, file)
    })()

    setGeneratedPfp(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          setGeneratedPfp(false)
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        })
      )
      .subscribe(({ data }) => {
        NotifyUtils.success({ message: 'Profile picture saved successfully. Please reload to see the changes.' })
        EventService.updateUser({
          id: userId,
          pfp: data.pfp
        })
      })
  }, [player, thumbnailAt, unsubscribe$, userId, videoId])

  return (
    <div className={clsx(props.className, 'fx fx-column gap-1')}>
      <Slider
        defaultValue={thumbnailAt}
        max={duration}
        step={0.1}
        onAfterChange={onPicker}
      />

      <div className="fx gap-1 mx-auto">
        <Button
          type="primary"
          size="small"
          disabled={generatedThumb || !!encoding}
          onClick={() => onSubmitThumb()}
        >
          {encoding ? 'Generating thumbnail' : 'Set thumbnail'}
        </Button>

        <Button
          type="primary"
          size="small"
          disabled={generatedPfp || !!encoding}
          onClick={() => onSubmitPfp()}
        >
          Set profile picture
        </Button>
      </div>
    </div>
  )
}
