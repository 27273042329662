import axios from 'axios'
import { IAnalyticModel, IPaginationQuery, ISessionModel, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class AnalyticApi {
  static readonly _prefix = '/analytics'

  static hm(params: IPaginationQuery = {}): TAxiosResponsePagination<IAnalyticModel> {
    return axios.get(`${AnalyticApi._prefix}/hm`, { params })
  }

  static mobile(params: IPaginationQuery = {}): TAxiosResponsePagination<IAnalyticModel> {
    return axios.get(`${AnalyticApi._prefix}/mobile`, { params })
  }

  static mobileSessions(params: IPaginationQuery = {}): TAxiosResponsePagination<ISessionModel> {
    return axios.get(`${AnalyticApi._prefix}/mobile/sessions`, { params })
  }

  static mobileUserStats({ userId }: { userId: number }): TAxiosResponseData<{
    countSessions: number
    countVibeStarts: number
    countVideosRecorded: number
  }> {
    return axios.get(`${AnalyticApi._prefix}/mobile/${userId}`)
  }

  static async exportHiringCSV(query: { startDate: Date; endDate: Date }): Promise<void> {
    const response = await axios.get(`${AnalyticApi._prefix}/hm/export`, {
      params: query,
      responseType: 'blob'
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    const fileName = `analytics_hiring_export_${query.startDate.toISOString().split('T')[0]}_${query.endDate.toISOString().split('T')[0]}.csv`
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }

  static async exportMobileCSV(query: { startDate: Date; endDate: Date }): Promise<void> {
    const response = await axios.get(`${AnalyticApi._prefix}/mobile/export`, {
      params: query,
      responseType: 'blob'
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    const fileName = `analytics_mobile_export_${query.startDate.toISOString().split('T')[0]}_${query.endDate.toISOString().split('T')[0]}.csv`
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)
  }
}
