import { Button, Checkbox, Col, Form, Input, InputNumber, Modal, Row, Select, Switch } from 'antd'
import { AxiosError } from 'axios'
import React from 'react'
import { ScrapedJobApi } from 'src/api'
import { ELocationType } from 'src/constants'
import { EScrapedJobProvider, ScrapedJobProviderOptions } from 'src/interfaces'
import { NotifyUtils } from '../../utils'
import { EditorQuill } from '../editor-quill'

const STATUS_OPTIONS = [
  { label: 'Active', value: 'active' },
  { label: 'Expired', value: 'expired' }
]

const ROLE_OPTIONS = [
  { label: 'Customer Service/Success', value: 'Customer Service' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Marketing', value: 'Marketing' },
  { label: 'Other', value: 'Other' }
]

const WORKING_TYPE_OPTIONS = [
  { label: 'On-site', value: ELocationType.ON_SITE },
  { label: 'Remote', value: ELocationType.REMOTE },
  { label: 'Hybrid', value: ELocationType.HYBRID }
]

const DEPARTMENTS_MAP = Object.freeze({
  'Customer Service': 'Customer Service/Success',
  Finance: 'Finance',
  'Investment Banking': 'Finance',
  Accounting: 'Finance',
  HR: 'Human resources',
  Law: 'Legal',
  Marketing: 'Marketing',
  'Public Relations': 'Marketing',
  Sales: 'Sales',
  'Business Development': 'Sales'
})

interface ModalCreateScrapedJobProps {
  onCreated?: () => void
}

export const ModalCreateScrapedJob: React.FC<ModalCreateScrapedJobProps> = ({
  onCreated
}) => {
  const [form] = Form.useForm()
  const [open, setOpen] = React.useState(false)

  const handleSubmit = () => {
    form.validateFields().then(async (values) => {
      try {
        if (values.departments && values.departments === 'Customer Service/Success') {
          values.departments = 'Customer Service'
        }

        await ScrapedJobApi.store(values)
        form.resetFields()
        setOpen(false)
        NotifyUtils.success({
          message: 'Create scraped job successfully'
        })
        onCreated?.()
      } catch (error) {
        NotifyUtils.handleAxiosError(error as AxiosError)
      }
    })
  }

  const handleRoleChange = (value: string) => {
    const departments = DEPARTMENTS_MAP[value as keyof typeof DEPARTMENTS_MAP] || ''
    form.setFieldsValue({ departments })
  }

  return (
    <>
      <Button onClick={() => setOpen(true)}>Create</Button>
      <Modal
        title="Create Scraped Job"
        open={open}
        onCancel={() => setOpen(false)}
        onOk={handleSubmit}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            provider: '',
            connected: false,
            notMatched: false
          }}
        >
          {/* Basic Job Information */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="provider" label="Provider">
                <Select<string | EScrapedJobProvider> options={[...ScrapedJobProviderOptions]}/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="status" label="Status">
                <Select
                  allowClear
                  showSearch
                  options={STATUS_OPTIONS}
                  mode="tags"
                  onChange={(value) => form.setFieldsValue({ status: value[value.length - 1] })}
                />
              </Form.Item>
            </Col>
          </Row>

          {/* Job Details */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="jobId" label="Job ID">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="jobUrl" label="Job URL">
                <Input/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="jobTitle" label="Job Title">
                <Input/>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
            <Form.Item name="standardizedTitle" label="Standardized Title">
              <Input/>
            </Form.Item>
          </Col> */}
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="role" label="Role">
                <Select
                  allowClear
                  showSearch
                  options={ROLE_OPTIONS}
                  onChange={handleRoleChange}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="departments" label="Departments">
                <Input readOnly/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="jobType" label="Job Type">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="jobWorkingType" label="Working Type">
                <Select
                  allowClear
                  options={WORKING_TYPE_OPTIONS}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="location" label="Location">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="jobAddress" label="Job Address">
                <Input/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="salaryInfo" label="Salary Info">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="applicants" label="Applicants">
                <InputNumber/>
              </Form.Item>
            </Col>
          </Row>

          {/* Company Information */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="companyName" label="Company Name">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="companyUrl" label="Company URL">
                <Input/>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="companyLogo" label="Company Logo">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={12}>

              <Row gutter={8}>
                <Col span={8}>
                  <Form.Item name="employeesFrom" label="Employees From">
                    <InputNumber placeholder="From"/>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item name="employeesPlus" label="Plus">
                    <Checkbox onChange={(e) => form.setFieldsValue({ employeesPlus: e.target.checked })}/>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="employeesTo" label="Employees To">
                    <InputNumber placeholder="To"/>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* Recruiter Information */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="recruiterName" label="Recruiter Name">
                <Input/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="recruiterUrl" label="Recruiter URL">
                <Input/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="recruiterBio" label="Recruiter Bio">
            <Input.TextArea rows={3}/>
          </Form.Item>

          <Form.Item name="description" label="Job Description">
            <EditorQuill onChange={e => form.setFieldValue('description', e)}/>
          </Form.Item>

          <Form.Item name="contactInfo" label="Contact Info">
            <Input.TextArea rows={2}/>
          </Form.Item>

          {/* Flags */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="connected" valuePropName="checked" label="Connected">
                <Switch/>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="notMatched" valuePropName="checked" label="Not Matched">
                <Switch/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
