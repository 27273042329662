import { EPlatformType } from '../../constants'
import { IObject } from '../object.interface'
import { IModel } from './model'
import { INotificationModel } from './notification.model'
import { IUserModel } from './user.model'

export enum ENotificationAction {
  GO_TO_VIBE_TUTORIAL = 'goToVibeTutorial',
  GO_TO_VIBE_CHECK = 'goToVibeCheck',
  GO_TO_QUIZ = 'goToQuiz',
  GO_TO_DASHBOARD = 'goToDashboard',
  GO_TO_PROFILE_SCREEN = 'goToProfileScreen',
  GO_TO_SUMMARY_SCREEN = 'goToSummaryScreen',
  GO_TO_AUDIO_VIBE_CHECK_SCREEN = 'goToAudioVibeCheckScreen'
}

export const ENotificationActionText = Object.freeze({
  [ENotificationAction.GO_TO_VIBE_TUTORIAL]: 'Go to vibe tutorial',
  [ENotificationAction.GO_TO_VIBE_CHECK]: 'Go to vibe check',
  [ENotificationAction.GO_TO_QUIZ]: 'Go to quiz',
  [ENotificationAction.GO_TO_DASHBOARD]: 'Go to dashboard',
  [ENotificationAction.GO_TO_PROFILE_SCREEN]: 'Go to profile screen',
  [ENotificationAction.GO_TO_SUMMARY_SCREEN]: 'Go to summary screen',
  [ENotificationAction.GO_TO_AUDIO_VIBE_CHECK_SCREEN]: 'Go to audio vibe check screen'
})

/**
 * @deprecated
 */
export enum ENotificationGroupUser {
  TEST_USERS = 'testUsers',
  HAD_VIBE_NOT_LINKEDIN = 'hadVibeNotLinkedin',
  VIBE_NOT_COMPLETED = 'vibeNotCompleted',
  QUIZ_NOT_COMPLETED = 'quizNotCompleted',
  VIBE_COMPLETED = 'vibeCompleted'
}

export enum ENotificationGroupTalent {
  TEST_USERS = 'testUsers',
  TALENT_LOOKING_FOR_JOB = 'talentLookingForJob',
  TALENT_NEW_SIGN_UP = 'talentNewSignUp',
  TALENT_WITH_VIDEO_VIEW_COUNT = 'talentWithVideoViewCount',
  TALENT_NO_VIBE_CHECK_NO_QUIZ = 'talentNoVibeCheckNoQuiz',
  TALENT_VIBE_CHECK_NO_QUIZ = 'talentVibeCheckNoQuiz',
  TALENT_VIBE_CHECK_QUIZ = 'talentVibeCheckQuiz',
  TALENT_NEW_UNREAD_MESSAGES = 'talentNewUnreadMessages'
}

export const ENotificationGroupTalentText = Object.freeze({
  [ENotificationGroupTalent.TEST_USERS]: 'Test Users',
  [ENotificationGroupTalent.TALENT_LOOKING_FOR_JOB]: 'Talent looking for job',
  [ENotificationGroupTalent.TALENT_NEW_SIGN_UP]: 'Talent new sign up',
  [ENotificationGroupTalent.TALENT_WITH_VIDEO_VIEW_COUNT]: 'Talent with video view count',
  [ENotificationGroupTalent.TALENT_NO_VIBE_CHECK_NO_QUIZ]: 'Talent no vibe check no quiz',
  [ENotificationGroupTalent.TALENT_VIBE_CHECK_NO_QUIZ]: 'Talent vibe check no quiz',
  [ENotificationGroupTalent.TALENT_VIBE_CHECK_QUIZ]: 'Talent vibe check quiz',
  [ENotificationGroupTalent.TALENT_NEW_UNREAD_MESSAGES]: 'Talent new unread messages'
})

export enum ENotificationMethodTypes {
  PUSH = 'push',
  EMAIL = 'email',
  IN_APP = 'in_app',
  SMS = 'sms'
}

export enum ENotificationUserPathTypes {
  CHILL = 'chill',
  DESPERATE = 'desperate'
}

export const ENotificationMethodTypesText = Object.freeze({
  [ENotificationMethodTypes.PUSH]: 'Push',
  [ENotificationMethodTypes.EMAIL]: 'Email',
  [ENotificationMethodTypes.IN_APP]: 'In-app',
  [ENotificationMethodTypes.SMS]: 'SMS'
})

export enum ENotificationGroupHiringManager {
  TEST_USERS = 'testUsers',
  HIRING_MANAGER_VIBE_NOT_COMPLETED = 'hiringManagerVibeNotCompleted',
  HIRING_NEW_SIGN_UP = 'hiringManagerNewSignUp',
  HIRING_MANAGER_NO_JOB = 'hiringManagerNoJob',
  HIRING_MANAGER_CREATE_JOB = 'hiringManagerCreateJob',
  HIRING_MANAGER_RECEIVED_LIKE = 'hiringManagerReceivedLike',
  HIRING_MANAGER_RECEIVED_NEW_MESSAGE = 'hiringManagerReceivedNewMessage',
  HIRING_MANAGER_SUMMARY_REPORT = 'hiringManagerSummaryReport'
}

export const ENotificationGroupHiringManagerText = Object.freeze({
  [ENotificationGroupHiringManager.TEST_USERS]: 'Test Users',
  [ENotificationGroupHiringManager.HIRING_MANAGER_VIBE_NOT_COMPLETED]: 'Hiring Manager who have not completed a vibe check',
  [ENotificationGroupHiringManager.HIRING_NEW_SIGN_UP]: 'Hiring Manager who just sign up',
  [ENotificationGroupHiringManager.HIRING_MANAGER_NO_JOB]: 'Hiring Manager who have not created a job',
  [ENotificationGroupHiringManager.HIRING_MANAGER_CREATE_JOB]: 'Hiring Manager who have created a job',
  [ENotificationGroupHiringManager.HIRING_MANAGER_RECEIVED_LIKE]: 'Hiring Manager who have received a like',
  [ENotificationGroupHiringManager.HIRING_MANAGER_RECEIVED_NEW_MESSAGE]: 'Hiring Manager who have received a new message',
  [ENotificationGroupHiringManager.HIRING_MANAGER_SUMMARY_REPORT]: 'Hiring Manager who have received a summary report'
})

export const ENotificationGroupUserText = Object.freeze({
  [ENotificationGroupUser.TEST_USERS]: 'Test Users',
  [ENotificationGroupUser.HAD_VIBE_NOT_LINKEDIN]: 'Users have vibe check and don\'t have linkedIn URL',
  [ENotificationGroupUser.QUIZ_NOT_COMPLETED]: 'Users who have not completed a quiz',
  [ENotificationGroupUser.VIBE_NOT_COMPLETED]: 'Users who have not completed a vibe check',
  [ENotificationGroupUser.VIBE_COMPLETED]: 'Users who have completed a vibe check'
})

export interface INotificationTemplateModel extends IModel {
  userId: number
  user?: IUserModel
  action?: ENotificationAction
  title?: string
  body?: string
  imageUrl?: string
  countSent?: number
  groupUser?: ENotificationGroupTalent | ENotificationGroupHiringManager
  joinFromDate?: string
  joinToDate?: string
  data?: IObject
  disabled?: boolean
  platform?: EPlatformType
  methods?: ENotificationMethodTypes[]
  userPath?: ENotificationUserPathTypes
  minVideoViewCount?: number
  mailchimpTemplateSlug?: string | null

  users?: IUserModel[]
  notifications?: INotificationModel[]
  nextExecutionDate?: Date
  intervalMilliseconds?: number
  sentAfterNumberOfDay?: number
}
