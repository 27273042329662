import { Button, Checkbox, Popconfirm, Space, TableProps, Tag } from 'antd'
import HTMLReactParser from 'html-react-parser'
import { get } from 'lodash'
import { toast } from 'react-toastify'
import { ModalNotificationTemplate, NotificationPreview } from 'src/components'
import { ENotificationActionText, ENotificationGroupHiringManagerText, ENotificationGroupTalentText, ENotificationMethodTypesText, INotificationTemplateModel } from 'src/interfaces'
import { browserHistory, generate } from 'src/router'
import { TimeZoneService } from 'src/services'
import { UserUtils } from 'src/utils'
import { NotificationTemplateApi } from '../../../../api'
import { EPaths } from '../../routes.path'

export const renderColumns = ({
  onSend,
  onUpdate,
  onDelete
}: {
  onSend?: (record: INotificationTemplateModel) => any
  onUpdate?: (record: INotificationTemplateModel) => any
  onDelete?: (record: INotificationTemplateModel) => any
} = {}): Required<TableProps<INotificationTemplateModel>>['columns'] => [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Creator',
    dataIndex: 'user',
    key: 'user',
    render: (val: Required<INotificationTemplateModel>['user']) => UserUtils.getFullName(val)
  },
  {
    title: 'Group of users',
    dataIndex: 'groupUser',
    render: (val: Required<INotificationTemplateModel>['groupUser']) => get(ENotificationGroupTalentText, val) ?? get(ENotificationGroupHiringManagerText, val)
  },
  {
    title: 'Action',
    dataIndex: 'action',
    render: (val: Required<INotificationTemplateModel>['action']) => ENotificationActionText[val]
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    render: (val: Required<INotificationTemplateModel>['title']) => (
      <span className="twoline-text">{HTMLReactParser(val || '')}</span>
    )
  },
  {
    title: 'Body',
    dataIndex: 'body',
    key: 'body',
    render: (val: Required<INotificationTemplateModel>['body']) => (
      <span className="twoline-text">{HTMLReactParser(val || '')}</span>
    )
  },
  {
    title: 'Image',
    dataIndex: 'imageUrl',
    key: 'imageUrl',
    render: (val: Required<INotificationTemplateModel>['imageUrl']) => !val
      ? null
      : <img src={val} alt="icon" style={{ height: 50 }}/>
  },
  {
    title: 'Count Sent',
    dataIndex: 'countSent',
    key: 'countSent'
  },
  {
    title: 'CreatedAt',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'UpdatedAt',
    dataIndex: 'updatedAt',
    key: 'updatedAt',
    render: (val: string) => TimeZoneService.format(val)
  },
  {
    title: 'Disabled',
    dataIndex: 'disabled',
    key: 'disabled',
    render: (val: boolean, record) => {
      return (
        <Checkbox
          checked={val}
          onChange={async () => {
            if (onUpdate) {
              onUpdate({
                ...record,
                disabled: !val
              })

              await NotificationTemplateApi.update(record.id, {
                disabled: !val
              })
            }
          }}
        />
      )
    }
  },
  {
    title: 'Methods',
    dataIndex: 'methods',
    key: 'methods',
    render: (val: string[]) => {
      return (
        <Space direction="vertical" size="small">
          {val.map((item) => (
            <Tag color="blue" key={item}>{get(ENotificationMethodTypesText, item)}</Tag>
          ))}
        </Space>
      )
    }
  },
  {
    title: 'Action',
    key: 'action',
    render: (text: string, record) => (
      <Space
        wrap
        size="small"
        direction="vertical"
      >
        <Button
          type="link"
          size="small"
          onClick={() => browserHistory.push(
            generate([EPaths.NOTIFICATION_TEMPLATE_SENT, { id: record.id }])
          )}
        >
          Status
        </Button>

        <Button
          size="small"
          type="primary"
          onClick={
            () => toast.success((props) => (
              <NotificationPreview
                notification={record}
                toastProps={props}
              />
            ), { icon: false })
          }
        >
          Preview
        </Button>

        {onSend && (
          <Popconfirm
            title="Confirm to send this notification?"
            onConfirm={() => onSend(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" type="primary">Send</Button>
          </Popconfirm>
        )}

        {onUpdate && (
          <ModalNotificationTemplate
            id={record.id}
            afterClose={(saved, doc) => saved && doc && onUpdate(doc)}
            btnProps={{
              type: 'ghost',
              size: 'small'
            }}
          >Edit
          </ModalNotificationTemplate>
        )}

        {onDelete && (
          <Popconfirm
            title="Confirm to delete?"
            onConfirm={() => onDelete(record)}
            okText="Yes"
            cancelText="No"
          >
            <Button size="small" danger type="primary">Delete</Button>
          </Popconfirm>
        )}
      </Space>
    )
  }
]
