import { Badge, Button, Checkbox, Input, Pagination, Popconfirm, Select, Table } from 'antd'
import { omit } from 'lodash'
import moment from 'moment'
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { EMPTY, catchError, finalize, from, takeUntil, tap } from 'rxjs'
import { ScrapedJobApi } from 'src/api'
import { DatePicker, DialogCopy, ModalAnalyzeCGPTLinkedinJobs, ModalCreateScrapedJob, UploadButton } from 'src/components'
import { EEnv, ignore } from 'src/constants'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, usePaginateParams, useUnsubscribe } from 'src/hooks'
import { EQueueJobStatus, ICampaignModel, IScrapedJobModel, ScrapedJobProviderOptions } from 'src/interfaces'
import { ERoutes, generate } from 'src/router'
import { BreadcrumbService, ChatGPTAnalyzeService, PaginationService } from 'src/services'
import { GlobalAction } from 'src/store'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from './columns'

const PAGE_TITLE = 'Scraped Jobs'

export const ScrapedJobs: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const [copyContent, setCopyContent] = useState('')
  const _paginationService = useMemo(() => new PaginationService<IScrapedJobModel>(ScrapedJobApi), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)
  const history = useHistory()

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<{ status?: EQueueJobStatus } & Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>({
    ...omit(pagination, ['page', 'limit', 'offset']),
    hasRecruiter: pagination.hasRecruiter === 'true' || undefined
  })

  const onDownload = useCallback(
    (params: typeof filter) => {
      GlobalAction.setLoading(true)
      from(ScrapedJobApi.download(params))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error) => {
            NotifyUtils.handleAxiosError(error)
            return EMPTY
          }),
          finalize(() => GlobalAction.setLoading(false))
        )
        .subscribe(ignore)
    },
    [unsubscribe$]
  )

  const onUpload = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { files } = e.target
      if (!files?.length) {
        return
      }
      const file = files[0]
      e.target.value = ''

      GlobalAction.setLoading(true)
      from(ScrapedJobApi.upload(file))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error) => {
            NotifyUtils.handleAxiosError(error)
            return EMPTY
          }),
          finalize(() => GlobalAction.setLoading(false))
        )
        .subscribe(() => {
          NotifyUtils.success({ message: 'Upload jobs successfully' })
          fetch(pagination)
        })
    },
    [fetch, pagination, unsubscribe$]
  )

  const fetchCampaign = useCallback((doc: IScrapedJobModel) => {
    GlobalAction.setLoading(true)
    return from(ScrapedJobApi.getCampaign(doc.id)).pipe(
      takeUntil(unsubscribe$),
      tap(({ data }) => {
        doc.campaign = {
          ...data,
          ...doc.campaign
        }
        doc.campaignId = data?.id
      }),
      catchError((error) => {
        NotifyUtils.handleAxiosError(error)
        return EMPTY
      }),
      finalize(() => GlobalAction.setLoading(false))
    )
  }, [unsubscribe$])

  const onSeedSubmissions = useCallback((doc: IScrapedJobModel) => {
    GlobalAction.setLoading(true)
    from(ScrapedJobApi.seedSubmissions(doc.id))
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => GlobalAction.setLoading(false))
      )
      .subscribe(() => NotifyUtils.success({ message: 'Seed videos successfully' }))
  }, [unsubscribe$])

  const onCopyClaimCampaignUrl = useCallback(
    (doc: IScrapedJobModel) => {
      const campaign = doc.campaign
      if (campaign?.slug) {
        return setCopyContent(`${EEnv.REACT_APP_HIRING_WEB_DNS}/public-campaigns/${campaign.slug}/submissions`)
      }

      fetchCampaign(doc).subscribe(
        ({ data }) => setCopyContent(`${EEnv.REACT_APP_HIRING_WEB_DNS}/public-campaigns/${data.slug}/submissions`)
      )
    },
    [fetchCampaign]
  )

  const onCopyPublicCampaignUrl = useCallback(
    (doc: IScrapedJobModel) => {
      const copyCampaign = (campaign: ICampaignModel) => {
        if (campaign) {
          const url = `${EEnv.REACT_APP_LANDING_WEB_DNS}/campaigns/${campaign.slug}`
          return setCopyContent(url)
        }
      }

      const campaign = doc.campaign
      if (campaign) {
        return copyCampaign(campaign)
      }

      fetchCampaign(doc).subscribe(
        ({ data }) => copyCampaign(data)
      )
    },
    [fetchCampaign]
  )

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.SCRAPED_JOBS,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const _chatGPTAnalyzeService = useMemo(() => new ChatGPTAnalyzeService<IScrapedJobModel>(), [])
  const selectedJobs = useBehaviorMapper(_chatGPTAnalyzeService.items$)

  const handleOnSearch = useCallback(() => {
    console.log('filter', filter)

    const { status, ...paging } = pagination

    setPagination({ ...paging, page: 1 })
    return pushPagination({
      ...paging,
      page: 1,
      ...filter,
      order: 'createdAt-DESC'
    })
  }, [filter, pagination, pushPagination, setPagination])

  const handleOnClear = useCallback(() => {
    const nonEmptyKeys = Object.keys(filter).filter((key) => filter[key] !== undefined && filter[key] !== '')

    const filteredFilter = nonEmptyKeys.reduce((acc: any, key) => {
      switch (typeof filter[key]) {
        case 'boolean':
          acc[key] = false
          break
        default:
          acc[key] = ''
          break
      }

      return acc
    }, {})

    setFilter(filteredFilter as any)
  }, [filter])

  return (
    <section className="fx fx-column fx-extend gap-2">
      <div className="fx fx-jc-space-between gap-2">
        <div className="fx fx-extend fx-ai-center fx-wrap-wrap gap-2">
          <Input
            allowClear
            placeholder="Search job..."
            style={{ flex: '0 0 150px' }}
            readOnly={loading}
            value={filter.keyword as string || ''}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))}
          />

          <Input
            allowClear
            placeholder="Search company..."
            style={{ flex: '0 0 150px' }}
            readOnly={loading}
            value={filter.companyName as string || ''}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              companyName: e.target.value
            }))}
          />

          <Select
            mode="multiple"
            allowClear
            disabled={loading}
            placeholder="Keywords"
            style={{ flex: '0 0 180px' }}
            value={(filter.role as string[])?.length ? filter.role : []}
            options={[
              { label: 'Customer success', value: 'customer success' },
              { label: 'Customer support', value: 'customer support' },
              { label: 'Customer service', value: 'customer service' },
              { label: 'Tech support', value: 'tech support' },
              { label: 'Technical support', value: 'technical support' },
              { label: 'Sales development representative', value: 'sales development representative' },
              { label: 'Business development representative', value: 'business development representative' },
              { label: 'Call agent', value: 'call agent' }
            ]}
            onChange={(value) => {
              console.log({ value })
              setFilter((prev) => ({
                ...prev,
                role: (value as string[])?.length ? value : ''
              }))
            }}
          />

          <Select
            allowClear
            disabled={loading}
            placeholder="Source"
            style={{ flex: '0 0 120px' }}
            value={filter.provider}
            options={[...ScrapedJobProviderOptions]}
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              provider: value || ''
            }))}
          />

          <Select
            allowClear
            disabled={loading}
            placeholder="Working type"
            style={{ flex: '0 0 120px' }}
            value={filter.workingType}
            options={[
              { label: 'Working type', value: '', disabled: true },
              { label: 'On-site', value: 'On-site' },
              { label: 'Remote', value: 'Remote' },
              { label: 'Hybrid', value: 'Hybrid' }
            ]}
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              workingType: value || ''
            }))}
          />

          <DatePicker
            allowClear
            disabled={loading}
            placeholder="From Date"
            value={filter.createdFrom ? moment(filter.createdFrom as string) : undefined}
            onChange={(date) => setFilter((prev) => ({
              ...prev,
              createdFrom: date?.startOf('day').toISOString() || ''
            }))}
          />

          <DatePicker
            allowClear
            disabled={loading}
            placeholder="To Date"
            value={filter.createdTo ? moment(filter.createdTo as string) : undefined}
            onChange={(date) => setFilter((prev) => ({
              ...prev,
              createdTo: date?.endOf('day').toISOString() || ''
            }))}
          />

          <Input
            allowClear
            type="number"
            min={0}
            step={1}
            placeholder="Min applicants"
            style={{ flex: '0 0 150px' }}
            readOnly={loading}
            value={filter.minApplicants as number}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              minApplicants: e.target.value ? Number(e.target.value) : undefined
            }))}
          />

          <Input
            allowClear
            type="number"
            min={0}
            step={1}
            placeholder="Max applicants"
            style={{ flex: '0 0 150px' }}
            readOnly={loading}
            value={filter.maxApplicants as number}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              maxApplicants: e.target.value
            }))}
          />

          {/* <Select
            allowClear
            disabled={loading}
            placeholder="Applicants"
            style={{ flex: '0 0 180px' }}
            value={filter.applicants}
            options={[
              { label: 'Applicants', value: '', disabled: true },
              { label: '0-10 applicants', value: '0-10' },
              { label: '10-50 applicants', value: '10-50' },
              { label: '50-100 applicants', value: '50-100' },
              { label: '100-500 applicants', value: '100-500' },
              { label: 'From 500 applicants', value: '500' }
            ]}
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              applicants: value || ''
            }))}
          /> */}

          <Input
            allowClear
            type="number"
            min={0}
            step={1}
            placeholder="Min employees"
            style={{ flex: '0 0 150px' }}
            readOnly={loading}
            value={filter.minEmployees as number}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              minEmployees: e.target.value
            }))}
          />

          <Input
            allowClear
            type="number"
            min={0}
            step={1}
            placeholder="Max employees"
            style={{ flex: '0 0 150px' }}
            readOnly={loading}
            value={filter.maxEmployees as number}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              maxEmployees: e.target.value
            }))}
          />

          {/* <Select
            allowClear
            disabled={loading}
            placeholder="Employees"
            style={{ flex: '0 0 180px' }}
            value={filter.employees}
            options={[
              { label: 'Employees', value: '', disabled: true },
              { label: '0-100 employees', value: '0-100' },
              { label: '100-300 employees', value: '100-300' },
              { label: '300-500 employees', value: '300-500' },
              { label: '500-700 employees', value: '500-700' },
              { label: '700-1000 employees', value: '700-1000' },
              { label: '1000-2000 employees', value: '1000-2000' },
              { label: '2000-5000 employees', value: '2000-5000' },
              { label: '5000-10000 employees', value: '5000-10000' },
              { label: 'From 10000 employees', value: '10000' }
            ]}
            onChange={(value) => setFilter((prev) => ({
              ...prev,
              employees: value || ''
            }))}
          /> */}

          <Checkbox
            style={{ flex: '0 0 120px' }}
            disabled={loading}
            checked={!!filter.hasRecruiter}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              hasRecruiter: e.target.checked
            }))}
          >
            Has poster
          </Checkbox>

          <Checkbox
            style={{ flex: '0 0 120px' }}
            disabled={loading}
            checked={!!filter.connected}
            onChange={(e) => setFilter((prev) => ({
              ...prev,
              connected: e.target.checked
            }))}
          >
            Connected
          </Checkbox>
        </div>

        <div className="fx-column gap-2">
          <div className="fx gap-2">
            <ModalAnalyzeCGPTLinkedinJobs jobs={selectedJobs}/>
            <ModalCreateScrapedJob
              onCreated={() => {
                fetch(pagination)
              }}
            />

            <UploadButton
              type="primary"
              disabled={loading}
              inputProps={{
                accept: '.csv',
                onChange: onUpload
              }}
            >
              Upload
            </UploadButton>

            <Popconfirm
              title="Export as csv?"
              okText="Yes"
              cancelText="No"
              disabled={loading || !dataSource.total}
              onConfirm={() => onDownload(filter)}
            >
              <Button type="primary" disabled={loading || !dataSource.total} style={{ position: 'relative' }}>
                Export

                <span
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    transform: 'translate(50%, -50%)'
                  }}
                >
                  <Badge
                    showZero
                    count={dataSource.total}
                  />
                </span>
              </Button>
            </Popconfirm>
          </div>
          <div className="fx fx-jc-flex-end gap-2">
            <Button onClick={handleOnClear}>Clear All</Button>
            <Button type="primary" onClick={handleOnSearch}>Search</Button>
          </div>
        </div>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns({
          disabled: loading,
          selectedIds: selectedJobs.map((job) => job.id),
          onEdit: (job) => history.push(generate([ERoutes.SCRAPED_JOB_DETAIL, { id: job.id }])),
          onSelect: (job, selected) => !selected
            ? _chatGPTAnalyzeService.add(job)
            : _chatGPTAnalyzeService.remove(job),
          onSeedSubmissions,
          onCopyClaimCampaignUrl,
          onCopyPublicCampaignUrl
        })}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />

      <DialogCopy
        open={!!copyContent}
        content={copyContent}
        afterClose={() => setCopyContent('')}
      />
    </section>
  )
}
