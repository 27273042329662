import { Badge, Button, Checkbox, Result } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { takeUntil } from 'rxjs/operators'
import { VideoApi } from 'src/api'
import { DialogIncreaseViews, InfiniteScroll, Loading, ModalAnalyzeCGPTVideos, SelectHashtag, SelectPrivacy } from 'src/components'
import { ModalAnalyzeGeminiVideos } from 'src/components/modal-analyze-gemini-videos'
import { EVideoPrivacy, EVideoType } from 'src/constants/enum'
import { useBehaviorMapper, useDidMountDebounce, useMountEffect, useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { IVideoModel } from 'src/interfaces'
import { BreadcrumbService, ChatGPTAnalyzeService, EventService, GodModeService, LoadMoreService } from 'src/services'
import { EPaths } from '../../routes.path'
import { VibeRatingVideo } from './video-item'

const PAGE_TITLE = 'Vibe Check Rating'

const loadVideoParams = Object.freeze({
  videoType: [EVideoType.VIDEO_VIBE_CHECK, EVideoType.VIDEO_CAMPAIGN, EVideoType.AUDIO_CAMPAIGN],
  includes: ['author', 'curators', 'hashtags', 'videoRatings', 'videoTranscription'],
  order: 'createdAt-DESC'
})

export const VibeRating: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const isGodMode = useBehaviorMapper(GodModeService.enable$)

  const _loadMoreService = useMemo(() => new LoadMoreService<IVideoModel>(VideoApi), [])
  const videos = useBehaviorMapper(_loadMoreService.items$)
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const [total, setTotal] = useState<number>()
  const [filter, setFilter] = useState<{
    hashtagIds?: number[]
    privacy?: EVideoPrivacy
  }>({})

  useUnsubscribeEffect((unsubscribe$) => {
    EventService.onUpdateUser$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((user) => {
        const items = _loadMoreService.items$.value
        for (const item of items) {
          if (item.author?.id === user.id) {
            item.author = {
              ...item.author,
              ...user
            }
          }
        }
        _loadMoreService.setItems([...items])
      })
  }, [_loadMoreService])

  const next = useCallback((params: typeof filter) => {
    _loadMoreService.loadMore({
      ...loadVideoParams,
      ...params
    })
  }, [_loadMoreService])

  useDidMountDebounce(() => {
    _loadMoreService.reset()
    next(filter)
  }, 100, [filter])

  useEffect(() => {
    _loadMoreService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(({ total }) => setTotal(total))
  }, [_loadMoreService, unsubscribe$])

  useMountEffect(() => {
    next({
      ...loadVideoParams,
      ...filter
    })
  })

  const [selected, setSelected] = useState<number[]>([])

  const onCheckChange = useCallback((checked: boolean, id: number) => {
    if (checked) {
      setSelected(
        (prev) => prev.includes(id)
          ? prev
          : [...prev, id]
      )
    } else {
      setSelected(
        (prev) => prev.filter(
          (val) => val !== id
        )
      )
    }
  }, [])

  const _chatGPTAnalyzeService = useMemo(() => new ChatGPTAnalyzeService<IVideoModel>(), [])
  const selectedItems = useBehaviorMapper(_chatGPTAnalyzeService.items$)
  const selectedItemsIds = useMemo(() => selectedItems.map(({ id }) => id), [selectedItems])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.VIBE_RATING,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <div className="mod-vibe-rating">
      <Loading show={loading && !videos.length}/>

      <div className="fx fx-ai-center gap-2 mb-3">
        <SelectHashtag
          className="fx fx-extend"
          isPlaylist
          allowClear
          onChange={(value) => setFilter((prev) => ({
            ...prev,
            hashtagIds: value ? [value as number] : []
          }))}
        />

        <SelectPrivacy
          className="fx fx-extend"
          onChange={(value) => setFilter((prev) => ({
            ...prev,
            privacy: value as EVideoPrivacy
          }))}
        />

        {Boolean(selected.length && isGodMode) && (
          <DialogIncreaseViews
            ids={selected}
            btnProps={{
              danger: true,
              type: 'primary',
              size: 'small'
            }}
            afterClose={() => setSelected([])}
          >
            Increase Views
          </DialogIncreaseViews>
        )}

        <ModalAnalyzeGeminiVideos videos={selectedItems}/>

        <ModalAnalyzeCGPTVideos videos={selectedItems}/>

        <Badge showZero count={total}/>
      </div>

      <section className="vibe-container">
        {!videos.length
          ? !loading && (
            <Result
              status="404"
              title="404"
              subTitle="Not found any vibes"
            />
          )
          : (
            <InfiniteScroll
              className="fx fx-extend fx-wrap-wrap gap-3"
              loader={null}
              scrollThreshold={0.95} // 95% scrollHeight
              dataLength={videos.length}
              next={() => next(filter)}
              hasMore={Boolean(_loadMoreService.hasMore)}
            >
              {videos.map((video) => (
                <VibeRatingVideo key={video.id} item={video}>
                  <div className="fx fx-jc-space-between gap-2">
                    {isGodMode && (
                      <Checkbox
                        checked={selected.includes(video.id)}
                        onChange={(e) => onCheckChange(e.target.checked, video.id)}
                      />
                    )}

                    <Button
                      size="small"
                      type="primary"
                      className="ml-auto"
                      disabled={loading}
                      onClick={() => _chatGPTAnalyzeService.toggle(video)}
                    >
                      {selectedItemsIds.includes(video.id) ? 'Remove' : 'Select'}
                    </Button>
                  </div>
                </VibeRatingVideo>
              ))}
            </InfiniteScroll>
          )}
      </section>
    </div>
  )
}
