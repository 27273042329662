import { Badge, Button, DatePicker, Input, message, Modal, Pagination, Table } from 'antd'
import { AxiosError } from 'axios'
import { omit } from 'lodash'
import { Moment } from 'moment'
import type { RangeValue } from 'rc-picker/lib/interface'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, from, takeUntil } from 'rxjs'
import { AnalyticApi } from 'src/api'
import { SelectCandidate } from 'src/components'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, useDidMountDebounce, usePaginateParams, useUnsubscribeEffect } from 'src/hooks'
import { IAnalyticModel } from 'src/interfaces'
import { BreadcrumbService, PaginationService } from 'src/services'
import { NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'
import { renderColumns } from '../columns'

const PAGE_TITLE = 'Analytics Talent'

export const AnalyticsMobile: FC = () => {
  const _paginationService = useMemo(
    () => new PaginationService<IAnalyticModel>({ endpoint: `${AnalyticApi._prefix}/mobile` }),
    []
  )
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>({
    ...omit(pagination, ['page', 'limit', 'offset']),
    userId: pagination.userId ? Number(pagination.userId) : undefined
  })

  const [stats, setStats] = useState<Awaited<ReturnType<typeof AnalyticApi.mobileUserStats>>['data']>()
  useUnsubscribeEffect((unsubscribe$) => {
    const userId = filter.userId ? Number(filter.userId) : undefined
    if (userId) {
      from(AnalyticApi.mobileUserStats({ userId }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            NotifyUtils.handleAxiosError(error)
            return EMPTY
          })
        )
        .subscribe(({ data }) => setStats(data))
    }

    return () => setStats(undefined)
  }, [filter.userId])

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.ANALYTIC_MOBILE,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [exportDates, setExportDates] = useState<[Moment | null, Moment | null]>([null, null])
  const { RangePicker } = DatePicker

  const handleExport = async () => {
    try {
      const [startDate, endDate] = exportDates
      if (!startDate || !endDate) {
        message.error('Please select both start and end dates')
        return
      }
      await AnalyticApi.exportMobileCSV({
        startDate: startDate.toDate(),
        endDate: endDate.toDate()
      })
      message.success('Export successful')
      setIsExportModalOpen(false)
      setExportDates([null, null])
    } catch (error) {
      message.error('Export failed')
    }
  }

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx gap-2 mb-2">
        <Input
          allowClear
          placeholder="Search..."
          style={{ flex: '0 0 20vw' }}
          readOnly={loading}
          value={filter.keyword as string}
          onChange={(e) => {
            e.persist()
            setFilter((prev) => ({
              ...prev,
              keyword: e.target.value
            }))
          }}
        />

        <SelectCandidate
          style={{ flex: '0 0 20vw' }}
          label="fullName"
          allowClear
          value={filter.userId ? Number(filter.userId) : undefined}
          onChange={(userId) => setFilter((prev) => ({
            ...prev,
            userId: userId ? Number(userId) : undefined
          }))}
        />

        {stats && (
          <div className="fx ml-auto fx-ai-center gap-3">
            <Badge showZero count={stats.countSessions}>
              <Button size="small">Num Sessions</Button>
            </Badge>
            <Badge showZero count={stats.countVibeStarts}>
              <Button size="small">Num Vibe Starts</Button>
            </Badge>
            <Badge showZero count={stats.countVideosRecorded}>
              <Button size="small">Num Videos Recorded</Button>
            </Badge>
          </div>
        )}

        <Button type="primary" onClick={() => setIsExportModalOpen(true)}>
          Export CSV
        </Button>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns()}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />

      <Modal
        title="Export Date Range"
        open={isExportModalOpen}
        onOk={handleExport}
        onCancel={() => {
          setIsExportModalOpen(false)
          setExportDates([null, null])
        }}
      >
        <RangePicker
          style={{ width: '100%' }}
          value={exportDates}
          onChange={(dates: RangeValue<Moment>) => setExportDates(dates as [Moment | null, Moment | null])}
        />
      </Modal>
    </section>
  )
}
