import { Button, DatePicker, Input, Modal, Pagination, Table, message } from 'antd'
import { omit } from 'lodash'
import type { Moment } from 'moment'
import type { RangeValue } from 'rc-picker/lib/interface'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { AnalyticApi } from 'src/api'
import { IPaginateCallback, IPaginateParams, useBehaviorMapper, useDidMountDebounce, usePaginateParams } from 'src/hooks'
import { BreadcrumbService, PaginationService } from 'src/services'
import { EPaths } from '../../routes.path'
import { renderColumns } from '../columns'

const PAGE_TITLE = 'Analytics Hiring Manager'

export const AnalyticsHM: FC = () => {
  const _paginationService = useMemo(() => new PaginationService({ endpoint: `${AnalyticApi._prefix}/hm` }), [])
  const loading = useBehaviorMapper(_paginationService.loading$)
  const dataSource = useBehaviorMapper(_paginationService.pagination$)

  const fetch = useCallback<IPaginateCallback>(
    (params) => _paginationService.paging(params),
    [_paginationService]
  )

  const { pagination, pushPagination, setPagination } = usePaginateParams(fetch)
  const [filter, setFilter] = useState<Omit<IPaginateParams, 'page' | 'limit' | 'offset'>>(
    omit(pagination, ['page', 'limit', 'offset'])
  )
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [exportDates, setExportDates] = useState<[Moment | null, Moment | null]>([null, null])
  const { RangePicker } = DatePicker

  const handleExport = async () => {
    try {
      const [startDate, endDate] = exportDates
      if (!startDate || !endDate) {
        message.error('Please select both start and end dates')
        return
      }
      await AnalyticApi.exportHiringCSV({
        startDate: startDate.toDate(),
        endDate: endDate.toDate()
      })
      message.success('Export successful')
      setIsExportModalOpen(false)
      setExportDates([null, null])
    } catch (error) {
      message.error('Export failed')
    }
  }

  useDidMountDebounce(() => {
    setPagination({ ...pagination, page: 1 })
    return pushPagination({
      ...pagination,
      page: 1,
      ...filter
    })
  }, 2000, [filter])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [{
      route: EPaths.ANALYTIC_MOBILE,
      label: PAGE_TITLE
    }]
    return () => {
      BreadcrumbService.items = []
    }
  }, [])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx fx-jc-space-between mb-2">
        <div className="fx fx-extend gap-2">
          <Input
            allowClear
            placeholder="Search..."
            style={{ flex: '0 0 30vw' }}
            readOnly={loading}
            value={filter.keyword as string}
            onChange={(e) => {
              e.persist()
              setFilter((prev) => ({
                ...prev,
                keyword: e.target.value
              }))
            }}
          />
          <Button type="primary" onClick={() => setIsExportModalOpen(true)}>
            Export CSV
          </Button>
        </div>
      </div>

      <Table
        className="fx-extend"
        rowKey="id"
        loading={loading}
        columns={renderColumns()}
        dataSource={dataSource.rows}
        pagination={false}
      />

      <Pagination
        className="fx-as-end mt-3"
        disabled={loading}
        total={dataSource.total}
        current={pagination.page}
        pageSize={pagination.limit}
        {
          ...{
            ...pagination,
            onChange(page, limit) {
              pushPagination({
                ...pagination,
                page,
                limit
              })
            }
          }
        }
      />

      <Modal
        title="Export Date Range"
        open={isExportModalOpen}
        onOk={handleExport}
        onCancel={() => {
          setIsExportModalOpen(false)
          setExportDates([null, null])
        }}
      >
        <RangePicker
          style={{ width: '100%' }}
          value={exportDates}
          onChange={(dates: RangeValue<Moment>) => setExportDates(dates as [Moment | null, Moment | null])}
        />
      </Modal>
    </section>
  )
}
