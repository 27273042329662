import { Form, Select } from 'antd'
import { FC, useEffect, useState } from 'react'
import { finalize, from, takeUntil } from 'rxjs'
import { NotificationTemplateApi } from 'src/api'
import { useUnsubscribe } from 'src/hooks'
import { NotifyUtils } from 'src/utils'
import { ErrorMessage } from '../error-message'

interface Props {
  loading?: boolean
  value?: string | null
  onChange?: (value: string | null) => void
}

export const MailChimpMail: FC<Props> = ({
  loading,
  value,
  onChange
}) => {
  const unsubscribe$ = useUnsubscribe()
  const [templates, setTemplates] = useState<{ slug: string; name: string }[]>([])
  const [loadingTemplates, setLoadingTemplates] = useState(false)

  useEffect(() => {
    setLoadingTemplates(true)
    from(NotificationTemplateApi.listMailchimpTemplates())
      .pipe(
        takeUntil(unsubscribe$),
        finalize(() => setLoadingTemplates(false))
      )
      .subscribe({
        next: ({ data }) => {
          setTemplates(data)
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [unsubscribe$])

  return (
    <Form.Item
      label="Mailchimp"
      labelAlign="left"
      labelCol={{ flex: '0 0 100px' }}
      className="m-0"
    >
      <Select
        disabled={loading || loadingTemplates}
        loading={loadingTemplates}
        placeholder="Select mailchimp template"
        value={value ?? undefined}
        onChange={(val) => onChange?.(val ?? null)}
        allowClear
        onClear={() => onChange?.(null)}
        options={templates.map(template => ({
          label: template.name,
          value: template.slug
        }))}
      />
      <ErrorMessage>{/* Add error message handling if needed */}</ErrorMessage>
    </Form.Item>
  )
}
