import axios from 'axios'
import { INotificationModel, INotificationTemplateModel, IPaginationQuery, TAxiosResponseData, TAxiosResponsePagination } from 'src/interfaces'

export class NotificationTemplateApi {
  static readonly _prefix = '/notification-template'

  static listMailchimpTemplates(): TAxiosResponseData<{ slug: string; name: string }[]> {
    return axios.get(`${NotificationTemplateApi._prefix}/mailchimp-templates`)
  }

  static index(params: IPaginationQuery = {}): TAxiosResponsePagination<INotificationTemplateModel> {
    return axios.get(`${NotificationTemplateApi._prefix}`, { params })
  }

  static store(payload: Pick<INotificationTemplateModel, 'action' | 'groupUser' | 'joinFromDate' | 'joinToDate' | 'title' | 'body' | 'imageUrl' | 'nextExecutionDate' | 'intervalMilliseconds' | 'platform' | 'methods' | 'disabled' | 'userPath' | 'minVideoViewCount' | 'sentAfterNumberOfDay' | 'mailchimpTemplateSlug' > & {
    data?: string
    userIds?: INotificationTemplateModel['id'][]
  }): TAxiosResponseData<INotificationTemplateModel> {
    return axios.post(`${NotificationTemplateApi._prefix}`, {
      ...payload,
      data: payload.data
        ? JSON.parse(payload.data)
        : undefined
    })
  }

  static show(id: INotificationTemplateModel['id']): TAxiosResponseData<INotificationTemplateModel> {
    return axios.get(`${NotificationTemplateApi._prefix}/${id}`)
  }

  static sent(id: INotificationTemplateModel['id']): TAxiosResponsePagination<INotificationModel> {
    return axios.get(`${NotificationTemplateApi._prefix}/${id}/notifications`)
  }

  static update(id: INotificationTemplateModel['id'], payload: Pick<INotificationTemplateModel, 'action' | 'groupUser' | 'joinFromDate' | 'joinToDate' | 'title' | 'body' | 'imageUrl' | 'nextExecutionDate' | 'intervalMilliseconds' | 'disabled'> & {
    data?: string
    userIds?: INotificationTemplateModel['id'][]
  }): TAxiosResponseData<INotificationTemplateModel> {
    return axios.put(`${NotificationTemplateApi._prefix}/${id}`, {
      ...payload,
      intervalMilliseconds: payload.intervalMilliseconds || null,
      nextExecutionDate: payload.nextExecutionDate || null,
      data: payload.data
        ? JSON.parse(payload.data)
        : undefined
    })
  }

  static send(id: INotificationTemplateModel['id']): TAxiosResponseData<void> {
    return axios.patch(`${NotificationTemplateApi._prefix}/${id}/send`)
  }

  static destroy(id: INotificationTemplateModel['id']): TAxiosResponseData<void> {
    return axios.delete(`${NotificationTemplateApi._prefix}/${id}`)
  }
}
