import { Input, Modal } from 'antd'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import { catchError, EMPTY, finalize, from, takeUntil } from 'rxjs'
import { IVideoModel } from 'src/interfaces'
import { VideoApi } from '../../api'
import { useUnsubscribe } from '../../hooks'
import { NotifyUtils } from '../../utils'
import { Loading } from '../loading'

interface IProps {
  className?: string
  video: IVideoModel
  onOk?: (video: IVideoModel) => any
  children?: ReactNode
}

export const ModalIVideoTitle: FC<IProps> = (props) => {
  const video = useMemo(() => props.video, [props.video])

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [title, setTitle] = useState(video.title)
  const unsubscribe$ = useUnsubscribe()

  useEffect(() => {
    if (video.title) {
      setTitle(video.title)
    }
  }, [video])

  const handleSave = () => {
    if (!title) return
    setLoading(true)
    const promise = VideoApi.updateTitle(video.id, title)

    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(() => {
        video.title = title
        props.onOk?.(video)
        setOpen(false)
      })
  }

  return (
    <>
      <span
        className={props.className}
        onClick={() => setOpen(true)}
      >
        {props.children || 'Open'}
      </span>

      {open && (
        <Modal
          open
          centered
          closable={!loading}
          maskClosable
          destroyOnClose
          title="Video Caption"
          okButtonProps={{ disabled: loading }}
          cancelButtonProps={{ disabled: loading }}
          onOk={handleSave}
          onCancel={() => setOpen(false)}
          afterClose={() => setOpen(false)}
        >
          <Loading show={loading}/>

          <div className={clsx('fx fx-column gap-2', { 'd-none': loading })}>
            <div>
              <strong>Video Caption</strong>
              <Input
                placeholder="Video Caption"
                style={{ minWidth: '200px' }}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
