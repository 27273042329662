import { Avatar, Badge, Button, Popconfirm, Result, Space, Switch } from 'antd'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import ReactPlayer from 'react-player'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { finalize, from, takeUntil } from 'rxjs'
import { CampaignApi, VideoApi } from 'src/api'
import { DialogAddToCampaign, InfiniteScroll, ModalCampaignSubmissionMetadata, SelectPrivacy, Video } from 'src/components'
import { EVideoPrivacy } from 'src/constants/enum'
import { useBehaviorMapper, useCallbackUnsubscribe, useDebounce, useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { ICampaignSubmissionModel, IVideoModel } from 'src/interfaces'
import { VideoThumbPicker } from 'src/partials'
import { ERoutes, generate } from 'src/router'
import { BreadcrumbService, EventService, LoadMoreService } from 'src/services'
import { GlobalAction, GlobalState, useStore } from 'src/store'
import { Helpers, NotifyUtils } from 'src/utils'
import { EPaths } from '../../routes.path'

const PAGE_TITLE = 'Campaigns'

export const Submissions: FC = () => {
  const unsubscribe$ = useUnsubscribe()
  const { id } = useParams<{ id: string }>()
  const { value: { loading: globalLoading } } = useStore(GlobalState)

  const _loadMoreService = useMemo(() => new LoadMoreService<ICampaignSubmissionModel>(
    { endpoint: `${CampaignApi._prefix}/${id}/submissions` },
    { loading: true }
  ), [id])
  const loading = useBehaviorMapper(_loadMoreService.loading$)
  const submissions = useBehaviorMapper(_loadMoreService.items$)
  const [player, setPlayer] = useState<ReactPlayer>()
  const [total, setTotal] = useState<number>()
  const [filter, setFilter] = useState<{ privacy?: EVideoPrivacy }>({})

  const next = useCallback((params: typeof filter) => {
    _loadMoreService.loadMore({ ...params })
  }, [_loadMoreService])

  const onPrivacyChanged = useCallbackUnsubscribe((unsubscribe$, video: IVideoModel) => {
    const newPrivacy = video.privacy === EVideoPrivacy.PUBLIC
      ? EVideoPrivacy.PRIVATE
      : EVideoPrivacy.PUBLIC
    GlobalAction.setLoading(true)
    const promise = VideoApi.updatePrivacy({
      videoId: video.id,
      privacy: newPrivacy
    })
    from(promise)
      .pipe(
        finalize(() => GlobalAction.setLoading(false)),
        takeUntil(unsubscribe$)
      )
      .subscribe({
        next: () => {
          video.privacy = newPrivacy
          NotifyUtils.success({ message: `Switched privacy to ${newPrivacy} successfully` })
        },
        error: NotifyUtils.handleAxiosError
      })
  }, [])

  useDebounce(() => {
    _loadMoreService.reset()
    next(filter)
  }, 100, [_loadMoreService, filter])

  useUnsubscribeEffect((unsubscribe$) => {
    EventService.onUpdateUser$
      .pipe(takeUntil(unsubscribe$))
      .subscribe((user) => {
        const items = _loadMoreService.items$.value
        for (const item of items) {
          if (item.author?.id === user.id) {
            item.author = {
              ...item.author,
              ...user
            }
          }
        }
        _loadMoreService.setItems([...items])
      })
  }, [_loadMoreService])

  useEffect(() => {
    _loadMoreService
      .pagination$
      .pipe(takeUntil(unsubscribe$))
      .subscribe(({ total }) => {
        setTotal(total)
      })
  }, [_loadMoreService, unsubscribe$])

  /**
   * breadcrumb
   */
  useEffect(() => {
    BreadcrumbService.items = [
      {
        route: EPaths.CAMPAIGN,
        label: PAGE_TITLE
      },
      {
        route: [EPaths.CAMPAIGN_SUBMISSIONS, { id }],
        label: 'Submissions'
      }
    ]

    return () => {
      BreadcrumbService.items = []
    }
  }, [id])

  return (
    <section className="fx fx-column fx-extend">
      <div className="fx gap-2 mb-3">
        <SelectPrivacy
          className="fx fx-extend"
          onChange={(value) => setFilter((prev) => ({
            ...prev,
            privacy: value as EVideoPrivacy
          }))}
        />

        <Badge showZero count={total}/>
      </div>

      <section className="fx fx-extend">
        {
          !submissions.length
            ? loading
              ? null
              : (
                <Result
                  status="404"
                  title="404"
                  subTitle="Not found any submission"
                  className="fx-as-center mx-auto"
                />
              )
            : (
              <InfiniteScroll
                className="fx fx-extend fx-wrap-wrap gap-3"
                loader={null}
                scrollThreshold={0.95} // 95% scrollHeight
                dataLength={submissions.length}
                next={() => next(filter)}
                hasMore={Boolean(_loadMoreService.hasMore)}
              >
                {submissions.map((submission) => {
                  const video = submission.video
                  if (!video) {
                    return null
                  }

                  return (
                    <div key={submission.id} className="fx fx-column gap-3" style={{ maxWidth: '250px' }}>
                      <Video
                        video={video}
                        playerProps={{
                          onReady: (player) => setPlayer(player),
                          style: {
                            width: '250px',
                            height: '400px'
                          }
                        }}
                      />

                      <VideoThumbPicker
                        className="fx-1"
                        player={player}
                        encoding={submission?.encoding}
                        video={video}
                      />

                      <div className="fx gap-2">
                        <Avatar size="large" style={{ verticalAlign: 'middle' }} src={submission.author?.pfp?.url}>
                          {submission.author?.fullName}
                        </Avatar>
                        <div className="fx fx-column">
                          <span>{submission.author?.fullName}</span>
                          <span style={{ fontSize: '12px', color: '#bdc3c7' }}>@{submission.author?.username}</span>
                        </div>
                      </div>

                      <Space>
                        {submission.portfolioUrl && <a rel="noreferrer" href={Helpers.addProtocolToUrl(submission.portfolioUrl)} target="_blank">Portfolio</a>}
                        {submission.resumeFileUrl && <a rel="noreferrer" href={submission.resumeFileUrl} target="_blank">Resume</a>}

                        <DialogAddToCampaign
                          btnProps={{ type: 'link', size: 'small' }}
                          videoId={video.id}
                        />
                      </Space>

                      <Space>
                        <Popconfirm
                          title="Are you sure to update privacy of this video?"
                          onConfirm={() => onPrivacyChanged(video)}
                          onCancel={() => null}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Switch
                            loading={globalLoading}
                            checkedChildren="Public"
                            unCheckedChildren="Private"
                            checked={video.privacy === EVideoPrivacy.PUBLIC}
                          />
                        </Popconfirm>

                        <Link to={generate([ERoutes.CANDIDATE_DASHBOARD, { id: video.userIdAuthor }])}>
                          Dashboard
                        </Link>

                        {!!submission.metadata && (
                          <ModalCampaignSubmissionMetadata metadata={submission.metadata}>
                            <Button type="link" size="small">Metadata</Button>
                          </ModalCampaignSubmissionMetadata>
                        )}
                      </Space>
                    </div>
                  )
                })}
              </InfiniteScroll>
            )
        }
      </section>
    </section>
  )
}
