import { Alert, Badge, Button, Divider, Input } from 'antd'
import { AxiosError } from 'axios'
import { ComponentProps, FC, useCallback, useEffect, useMemo, useState } from 'react'
import { EMPTY, catchError, finalize, from, takeUntil } from 'rxjs'
import { AIApi } from 'src/api'
import { ModalFullscreen } from 'src/components'
import { ignore } from 'src/constants'
import { useAsRef, useUnsubscribe, useUnsubscribeEffect } from 'src/hooks'
import { EOpenAIMessageRole, IOpenAIMessage, IVideoModel } from 'src/interfaces'
import { AxiosUtils, NotifyUtils, UserUtils } from 'src/utils'
import { ModalMessages } from '../modal-messages'
import Style from './style.module.scss'

interface IProps extends Omit<ComponentProps<typeof ModalFullscreen>, 'onOk' | 'afterClose'> {
  videos?: IVideoModel[]
  afterClose?: () => any
  btnProps?: ComponentProps<typeof Button>
}

export const ModalAnalyzeGeminiVideos: FC<IProps> = ({
  btnProps,
  ...props
}) => {
  const unsubscribe$ = useUnsubscribe()
  const videos = useMemo(() => props.videos || [], [props.videos])
  const [open, setOpen] = useState(false)
  const afterCloseRef = useAsRef(props.afterClose)

  useEffect(() => {
    if (!open) {
      afterCloseRef.current?.()
    }
  }, [afterCloseRef, open])

  const [loading, setLoading] = useState(false)
  const [prompt, setPrompt] = useState('')
  const [promptLib, setPromptLib] = useState<{
    [key: string]: {
      transcription?: string
      quizResult?: string
      quizSummary?: string
      resume?: string
    }
  }>({})
  const [result, setResult] = useState<{ [key: string]: IOpenAIMessage[] }>({})

  useUnsubscribeEffect((unsubscribe$) => {
    setResult({})
    setPromptLib({})

    if (open && videos.length) {
      setLoading(true)
      from(AIApi.prepareVideosData({ videoIds: videos.map((v) => v.id) }))
        .pipe(
          takeUntil(unsubscribe$),
          catchError((error: AxiosError) => {
            NotifyUtils.handleAxiosError(error)
            return EMPTY
          }),
          finalize(() => setLoading(false))
        )
        .subscribe(({ data }) => {
          setPromptLib(data.reduce<{
            [key: string]: {
              transcription?: string
              quizResult?: string
              quizSummary?: string
              resume?: string
            }
          }>((acc, { videoId, ...rest }) => {
            acc[videoId] = {
              ...rest,
              quizResult: [
                rest.quizResult?.ambScores
                  ? JSON.stringify(rest.quizResult?.ambScores.reduce((acc, { label, score }) => ({
                    ...acc,
                    [label]: score
                  }), {}), null, 2)
                  : '',
                rest.quizResult?.coreScores
                  ? JSON.stringify(rest.quizResult?.coreScores.reduce<Record<string, number>>((acc, items) => {
                    for (const item of items) {
                      acc[item.label] = item.score
                    }
                    return acc
                  }, {}), null, 2)
                  : ''
              ].filter(Boolean).join('\n'),
              // quizResult: rest.quizResult
              //   ? [
              //     'Drives: ' + rest.quizResult?.ambScores?.filter((_, index) => index <= 2).map(({ label }) => label).join(', ') || '',
              //     'Work Style: ' + rest.quizResult?.coreScores?.map((pairs) => pairs.find((w) => w.score >= 50)?.label) || ''
              //   ].join('\n')
              //   : '',
              quizSummary: rest.quizResult ? 'Executive Overview: ' + rest.quizResult?.snippets?.[0]?.svg?.join('\n') || '' : ''
            }
            return acc
          }, {}))
        })
    }
  }, [open, videos])

  const onSubmit = useCallback(() => {
    setResult({})

    const promise = (async () => {
      const used = {
        transcription: prompt.includes('[TRANSCRIPTION]'),
        quizResult: prompt.includes('[QUIZ_RESULTS]'),
        quizSummary: prompt.includes('[QUIZ_SUMMARY]'),
        resume: prompt.includes('[RESUME]')
      }

      for (const video of videos) {
        const lib = promptLib[video.id] || {}
        const isUsed = (Object.keys(used) as Array<keyof typeof lib>).filter((k) => used[k] && lib[k]).length
        if (!isUsed) {
          continue
        }

        const content = prompt
          .replaceAll('[TRANSCRIPTION]', lib.transcription || '[TRANSCRIPTION]')
          .replaceAll('[QUIZ_RESULTS]', lib.quizResult || '[QUIZ_RESULTS]')
          .replaceAll('[QUIZ_SUMMARY]', lib.quizSummary || '[QUIZ_SUMMARY]')
          .replaceAll('[RESUME]', lib.resume || '[RESUME]')

        const messages = [{
          id: 0,
          role: EOpenAIMessageRole.USER,
          content
        }]

        await AIApi.messaging({ messages })
          .then(({ data }) => setResult((prev) => ({
            ...prev,
            [video.id]: [
              ...messages,
              {
                id: 1,
                role: EOpenAIMessageRole.ASSISTANT,
                content: data.content
              }
            ]
          })))
          .catch((error: AxiosError) => {
            setResult((prev) => ({
              ...prev,
              [video.id]: [
                ...messages,
                {
                  id: 1,
                  role: EOpenAIMessageRole.ASSISTANT,
                  content: `<span style="color: red;">${AxiosUtils.getApiErrorMessage(error)}</span>`
                }
              ]
            }))
          })
      }
    })()

    setLoading(true)
    from(promise)
      .pipe(
        takeUntil(unsubscribe$),
        catchError((error: AxiosError) => {
          NotifyUtils.handleAxiosError(error)
          return EMPTY
        }),
        finalize(() => setLoading(false))
      )
      .subscribe(ignore)
  }, [unsubscribe$, prompt, videos, promptLib])

  return (
    <>
      <span className={Style.modalAnalyzeGeminiVideos}>
        <Button
          type="primary"
          {...btnProps}
          disabled={btnProps?.disabled || !videos.length}
          onClick={() => setOpen(true)}
        >
          {props.children || 'Try Gemini Prompt'}
        </Button>
        <Badge
          showZero
          count={videos.length}
        />
      </span>

      <ModalFullscreen
        title="Try Feed Gemini"
        // wrapClassName={Style.modalAnalyzeGeminiVideos}
        open={open}
        closable={!loading} // display X icon
        keyboard={false} // disable close on press ESC
        maskClosable={false} // disable close on click outside
        okButtonProps={{ disabled: !prompt || loading }}
        cancelButtonProps={{ disabled: loading }}
        okText="Submit"
        cancelText="Close"
        onOk={onSubmit}
        onCancel={() => setOpen(false)}
      >
        <div className="fx fx-column gap-3">
          <div className="fx fx-column gap-2">
            <Input.TextArea
              // showCount
              // maxLength={500}
              style={{ height: 120, resize: 'none' }}
              placeholder="Gemini Prompt..."
              disabled={loading}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
            />

            <Alert
              type="info"
              message={(
                <>
                  {/* <div>Use [VVC_VIDEO] then scripts will replace with video file</div> */}
                  <div>Use [TRANSCRIPTION] then scripts will replace with video's transcription</div>
                  <div>Use [QUIZ_RESULTS] then scripts will replace with candidate's motivator/work style results</div>
                  <div>Use [QUIZ_SUMMARY] then scripts will replace with candidate's quiz executive summary</div>
                  <div>Use [RESUME] then scripts will replace with candidate's resume text(if extractable)</div>
                  {/* <div>Use [QUESTIONS] // TODO ???</div> */}
                </>
              )}
            />
          </div>

          <div className="fx fx-column gap-2">
            {videos.map((video) => (
              <div key={video.id}>
                <Divider/>
                <div className="fx gap-2">
                  <div className="fx-1">{video.id}</div>
                  <div className="fx-1">{UserUtils.getFullName(video.author)}</div>
                  <div className="fx-1">{!promptLib[video.id]?.transcription ? 'No Transcription' : 'Have Transcription'}</div>
                  <div className="fx-1">{!promptLib[video.id]?.resume ? 'No Resume' : 'Have Resume'}</div>
                  <div className="fx-1">{!promptLib[video.id]?.quizResult ? 'No Quiz' : 'Have Quiz'}</div>
                  <div className="fx-1 fx fx-jc-flex-end">
                    {result[video.id]?.length
                      ? <ModalMessages messages={result[video.id] || []}/>
                      : loading
                        ? 'Loading...'
                        : 'No data to feed'}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ModalFullscreen>
    </>
  )
}
